import { GET_ACCOUNT_FEATURE_FLAGS } from '../../account_info/accountInfo.graphql';
import client from '../apollo/portalBFF.apolloClient';

export type Flags = {
  portal_nav: number;
  portal_nav_banner: number;
  upload_flyer: number;
  approval_center: number;
  approval_center_banner: number;
  org_uploader: number;
  managed_services: number;
  comm_free: number;
  comm_free_release: boolean;
};

export default async function getFeatureFlags(): Promise<Flags> {
  try {
    const { data, errors } = await client.query({
      query: GET_ACCOUNT_FEATURE_FLAGS,
      fetchPolicy: 'cache-first',
    });

    if (errors && errors.length > 0) {
      throw errors[0];
    }

    const {
      profile: { featureFlags },
      featureFlags: ff,
    } = data;

    return {
      ...featureFlags,
      comm_free_release: Boolean(ff.release_comm_free_flag),
    };
  } catch (e) {
    console.error('Unable to query featureFlags. ', e);
    return {
      portal_nav: 0,
      portal_nav_banner: 0,
      upload_flyer: 0,
      approval_center: 0,
      approval_center_banner: 0,
      org_uploader: 0,
      managed_services: 0,
      comm_free: 0,
      comm_free_release: false,
    };
  }
}
