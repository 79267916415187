import gql from 'graphql-tag';

export const GET_ACCOUNT_CONTEXT = gql`
  query getAccountContext {
    profile {
      id
      userId
      hierarchy {
        id
        type
      }
      scopes
      academicGroupMemberships {
        groupName
        groupType
        groupId
        hierarchyName
        hierarchyType
        hierarchyId
        roles
      }
      linkedAccounts {
        userId
        email
        hierarchy {
          id
          type
          name
        }
        scopes
      }
    }
  }
`;

export const GET_ACCOUNT_FEATURE_FLAGS = gql`
  query getAccountFeatureFlags {
    profile {
      id
      featureFlags {
        portal_nav
        portal_nav_banner
        upload_flyer
        approval_center
        approval_center_banner
        org_uploader
        managed_services
        comm_free
      }
    }
    featureFlags
  }
`;

export const GET_STAFF_ACCOUNT_INFO = gql`
  query getStaffAccountInfo {
    profile {
      id
      userId
      email
      firstName
      lastName
      scopes
      hierarchy {
        id
        type
      }
      subscriptions {
        id
        userId
        type
        audienceType
        audienceId
        hierarchyType
        hierarchyId
        managingContext
        config {
          ... on FlyerEmailSubscriptionConfig {
            email
            textOnly
            userJoinDate
          }
        }
        labels
        status
      }
      academicGroupMemberships {
        groupName
        groupType
        groupId
        hierarchyName
        hierarchyType
        hierarchyId
        roles
      }
    }
  }
`;

export const UPDATE_MY_PROFILE = gql`
  mutation updateMyProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
      userId
      firstName
      lastName
      email
    }
  }
`;

export const CHANGE_MY_SUBSCRIPTIONS = gql`
  mutation changeMySubscriptions($input: SubscriptionChangesInput!) {
    changeSubscriptions(input: $input) {
      id
      userId
      type
      audienceType
      audienceId
      hierarchyType
      hierarchyId
      managingContext
      filters
      labels
      status
      statusChangeReason
    }
  }
`;

// ORGS
export const GET_ORG_ACCOUNT_INFORMATION = gql`
  query getAccountInformation {
    profile {
      id
      firstName
      lastName
      email
      userId
      hierarchy {
        type
      }
    }
  }
`;

export const SET_ORG_ACCOUNT_INFORMATION = gql`
  mutation saveOrganizationInfo($input: TransitionalUpdateOrganizationInput!) {
    updateOrganizationTransitional(input: $input) {
      companyName
      firstName
      lastName
      zip
      phone
      url
      type
      email
      tax
    }
  }
`;

export const CHANGE_NOTIFICATION_PREFERENCES = gql`
  mutation changeNotificationPreferences($input: NotifInput) {
    changeNotificationPreferences(input: $input) {
      notifType
      notifTypeId
      notifStatus
      refId
      updatedAt
      updatedBy
    }
  }
`;

export const GET_NOTIFICATION_PREFERENCES = gql`
  query getNotificationPreferences($input: GetNotifInput!) {
    getNotificationPreferences(input: $input) {
      notifType
      notifTypeId
      notifStatus
      refId
    }
  }
`;

export const VERIFY_CHANGE_EMAIL = gql`
  mutation verifyChangeEmail($input: VerifyChangeEmailInput) {
    verifyChangeEmail(input: $input) {
      email
      status
    }
  }
`;
