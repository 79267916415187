import { useQuery } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { components, elements } from '@peachjar/components';
import { css } from 'emotion';
import gql from 'graphql-tag';
import idx from 'idx';
import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HelpCard from '../../src/components/HelpCard';
import { apolloClient as bffClient } from '../_app/apollo/portalBFF.apolloClient';
import MessageBanner from '../_app/components/Banners/MessageBanner';
import {
  getRolesFromPermissions,
  shouldUserSeeChildren,
} from '../_app/components/RoleCheck';
import SubappHeader from '../_app/components/SubappHeader';
import { APP_ROUTES, NOTIFICATIONS } from '../_app/constants';
import { GET_CREDIT_PACKAGES } from '../_app/queries/deals';
import {
  approvalsAllowedRoles,
  campaignsAllowedRoles,
  dealsAllowedRoles,
  reportingAllowedRoles,
} from '../_app/RolesEnum';
import desktopBlueIcon from '../assets/desktop_blue.svg';
import desktopSVG from '../assets/icon-orange-desktop.svg';
import distributionIcon from '../assets/icon_distribution.svg';
import ApproveManageFlyers from '../assets/Illustration_Approvals.svg';
import ManageSettings from '../assets/Illustration_Settings.svg';
import SendPostFlyer from '../assets/Illustration_Upload_Pdf.svg';
import approvalsDisabledSVG from '../assets/section-approvals-disabled.svg';
import approvalsSVG from '../assets/section-approvals.svg';
import dealsDisabledSVG from '../assets/section-deals-disabled.svg';
import dealsSVG from '../assets/section-deals.svg';
import distributionDisabledSVG from '../assets/section-distribution-disabled.svg';
import distributionSVG from '../assets/section-distribution.svg';
import reportingDisabledSVG from '../assets/section-reporting-disabled.svg';
import reportingSVG from '../assets/section-reporting.svg';
import metricsIcon from '../assets/yay.svg';
import config from '../config';
import CyberDealsBanner from '../deals/components/banners/cyberSale/CyberSale.container';
import CyberSaleDisclaimer from '../deals/components/banners/cyberSale/CyberSaleDisclaimer';
import { FLYER_PURCHASE_DISCOUNT_CODES } from '../deals/constants';
import DistributionSettingsNotification from '../distribution_schedule/DistributionSettingsNotification';
import ScheduleDistributionNotification, {
  SCHEDULE_DISTRIBUTION_VISITED_FLAG,
} from '../distribution_schedule/ScheduleDistributionNotification';
import isCyberSaleLive from '../helpers/deals/isCyberSaleLive';
import { isFlagSet } from '../helpers/localStorage';
import dashboardJSON from './_dashboard.json';
import StarterKitBanner from './components/StarterKitBanner';

const {
  banners: {
    welcome,
    welcome_org,
    communityFree,
    verifyStatus,
    starterKit,
    noRole,
    removedAccount,
  },
} = dashboardJSON;

const { notifyWarn } = components.Notifications;
const { ButtonSecondarySmall } = components.Buttons;
const { errorKey } = NOTIFICATIONS;
const { Paragraph } = elements.typography;

const {
  REACT_APP_PEACHJAR_HOMEPAGE,
  REACT_APP_PORTAL_APP_URL,
  REACT_APP_PEACHJAR_ORG_SETTINGS_LINK,
} = config;

const { STARTER_KIT_CODE } = FLYER_PURCHASE_DISCOUNT_CODES;

const GET_ACTIVE_REPORTING_SECTION = gql`
  {
    activeReportingSection @client
  }
`;

const getDashboardSections = reportingRoute => [
  {
    headline: 'Distribution',
    subheadline:
      'Send and post flyer announcements and opportunities to families or staff of each school.',
    illustration: distributionSVG,
    illustrationDisabled: distributionDisabledSVG,
    linksTo: APP_ROUTES.campaigns,
    allowedRoles: campaignsAllowedRoles,
    filter: ['portal_school', 'portal_org'],
  },
  {
    headline: 'Approvals',
    subheadline:
      'Manage flyers waiting for your approval and view the approval history.',
    illustration: approvalsSVG,
    illustrationDisabled: approvalsDisabledSVG,
    linksTo: APP_ROUTES.approvals.index,
    allowedRoles: approvalsAllowedRoles,
    filter: ['portal_school'],
  },
  {
    headline: 'Deals',
    subheadline:
      "Browse credit packages and see if there's a deal that helps you reach more families for less.",
    illustration: dealsSVG,
    illustrationDisabled: dealsDisabledSVG,
    linksTo: APP_ROUTES.deals,
    allowedRoles: dealsAllowedRoles,
    filter: ['portal_org'],
  },
  {
    headline: 'Reporting',
    subheadline:
      'Use flyer distribution and performance data to measure the reach and impact of your flyer.',
    illustration: reportingSVG,
    illustrationDisabled: reportingDisabledSVG,
    linksTo: reportingRoute,
    allowedRoles: reportingAllowedRoles,
    filter: ['portal_school', 'portal_org'],
  },
];

const Dashboard = ({ sodUser, handleWarning, flags, history, gqlStuff }) => {
  const { error: apolloError } = gqlStuff.apolloHelpers;

  // Runs after component mounts
  React.useEffect(() => {
    const error = localStorage.getItem(errorKey) || null;
    if (error) {
      handleWarning(error);
      localStorage.removeItem(errorKey);
    }
  }, []);
  const { loading, error, data } = useQuery(GET_CREDIT_PACKAGES, {
    client: bffClient,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    return <div>Uh oh! We encountered an error. Please try again.</div>;
  }
  if (loading) {
    return <></>;
  }
  const dealsArray = idx(data, _ => _.getCreditPackages) || null;
  const hasStarterKit =
    dealsArray && dealsArray.find(d => d.code === STARTER_KIT_CODE);
  const { org_uploader, comm_free, comm_free_release } = flags;
  const doesCreditPackagesHaveStartKit = dealsArray
    .map(deal => deal.code)
    .includes('STARTER_KIT');
  const name = idx(sodUser, _ => _.firstName) || null;
  const sodSellerType = idx(sodUser, _ => _.organization.orgCategory) || '';
  const sodSellerTaxId = idx(sodUser, _ => _.organization.taxId) || '';
  const sodSellerLod = idx(sodUser, _ => _.organization.lod) || '';
  const approverLevel = idx(sodUser, _ => _.permission.approverLevel) || null;
  const adminLevel = idx(sodUser, _ => _.permission.adminLevel) || null;
  const uploaderLevel = idx(sodUser, _ => _.permission.uploaderLevel) || null;
  const showNoRoleBanner = !adminLevel && !uploaderLevel && !approverLevel;
  const permissions = {
    approverLevel,
    adminLevel,
    uploaderLevel,
  };
  const isDistrictAdmin = adminLevel === 'district';
  const isDistrictApprover = approverLevel === 'district';
  const hierarchyId = idx(sodUser, _ => _.hierarchy.id) || '';
  const scheduleDistributionVisited = isFlagSet(
    SCHEDULE_DISTRIBUTION_VISITED_FLAG
  );
  const isOrgUser = uploaderLevel === 'org';
  const isCommunityFreeUser = comm_free === 1 || comm_free_release;
  const isOrgOrCommunityFree = isOrgUser || isCommunityFreeUser;
  const isOrgWithoutBanner =
    isOrgOrCommunityFree && !doesCreditPackagesHaveStartKit;
  const doesShowDefault =
    (isOrgWithoutBanner || !isOrgOrCommunityFree) && !showNoRoleBanner;
  const isOrgUploaderFeaturesEnabled = org_uploader === 1;
  const requiresLodBannerPrompt =
    sodSellerType === 'nonprofit' && (!sodSellerTaxId || !sodSellerLod);
  const dashboardFilter =
    isOrgOrCommunityFree && isOrgUploaderFeaturesEnabled
      ? 'portal_org'
      : 'portal_school';

  const generateDashboardItems = (
    { approverLevel, adminLevel, uploaderLevel },
    filter = 'portal_school',
    cache
  ) => {
    const reportingRoute =
      idx(cache, _ => _.activeReportingSection) ||
      APP_ROUTES.reporting.allFlyers;
    const sectionIsEnabled = allowedRoles => {
      const userRoles = getRolesFromPermissions(
        approverLevel,
        adminLevel,
        uploaderLevel
      );
      return shouldUserSeeChildren(allowedRoles, userRoles);
    };
    return getDashboardSections(reportingRoute)
      .filter(section => section.filter.includes(filter))
      .map((section, index, array) => {
        const { linksTo, allowedRoles } = section;
        const isEnabled = sectionIsEnabled(allowedRoles);
        const isLastCard = index === array.length - 1;
        return (
          <Grid
            item
            xs={index === 0 ? 12 : 6}
            className={cn.section}
            key={section.headline}
            onClick={() =>
              isEnabled ? history.push(linksTo) : handleWarning('roleDenied')
            }
          >
            <div
              className={`${cn.sectionBG} ${
                !isEnabled ? cn.sectionBGdisabled : ''
              }`}
            >
              <div
                className={`${cn.sectionImg} ${
                  !isEnabled ? cn.sectionDisabled : ''
                }`}
              >
                <img
                  src={
                    isEnabled
                      ? section.illustration
                      : section.illustrationDisabled
                  }
                  alt="Section Illustration"
                />
              </div>
              <div
                className={`${cn.sectionContent} ${
                  isLastCard ? cn.lastCard : ''
                }`}
              >
                <h3
                  className={`${cn.sectionTitle} ${
                    !isEnabled ? cn.sectionDisabled : ''
                  }`}
                >
                  {section.headline}
                </h3>
                <Paragraph>{section.subheadline}</Paragraph>
              </div>
            </div>
          </Grid>
        );
      });
  };

  const generateTrainingGuideItems = ({
    approverLevel,
    adminLevel,
    uploaderLevel,
  }) => (
    <div className={cn.guideContainer}>
      <Grid container spacing={8}>
        {adminLevel && (
          <Grid item>
            <HelpCard
              link="https://info.peachjar.com/manage-school-and-district-settings"
              icon={ManageSettings}
              headline="How to Manage Settings"
              dataTestId="click-manage-settings-help"
              tag="Admins"
              size="large"
            />
          </Grid>
        )}
        {uploaderLevel && (
          <Grid item>
            <HelpCard
              link="https://info.peachjar.com/how-to-upload-flyers"
              rel="noopener"
              icon={SendPostFlyer}
              headline="How to Send & Post a Flyer"
              dataTestId="click-send-post-help-orgs"
              tag="Uploaders"
              size="large"
            />
          </Grid>
        )}
        {approverLevel && (
          <Grid item>
            <HelpCard
              link="https://info.peachjar.com/how-to-approve-and-manage-flyers"
              icon={ApproveManageFlyers}
              headline="How to Approve &amp; Manage Flyers"
              dataTestId="click-approve-manage-help"
              tag="Approvers"
              size="large"
            />
          </Grid>
        )}
      </Grid>
    </div>
  );

  const generateWelcomeSubHeadline = () =>
    welcome.subheadline.replace(':PORTAL_APP_URL', REACT_APP_PORTAL_APP_URL);

  const GenerateSubHeader = ({ children }) => (
    <div className={`view-dashboard ${cn.viewDashboard}`}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <SubappHeader className={cn.sectionHeadline}>
            {name ? `Hi, ${name}!` : 'Hi!'}
          </SubappHeader>
        </Grid>
        {children}
      </Grid>
    </div>
  );

  return (
    <>
      {apolloError ? (
        <GenerateSubHeader>
          <div className={cn.removedAccountBanner}>
            <MessageBanner
              headline={removedAccount.headline}
              subheadline={removedAccount.subheadline}
              iconSrc={desktopSVG}
              variant="orange"
            />
          </div>
        </GenerateSubHeader>
      ) : (
        <Query query={GET_ACTIVE_REPORTING_SECTION}>
          {({ data }) => (
            <>
              <GenerateSubHeader>
                {/* <MaintenanceBanner /> */}

                {(isOrgUser || isCommunityFreeUser) &&
                  doesCreditPackagesHaveStartKit &&
                  !isCyberSaleLive() && (
                    <StarterKitBanner
                      headline={starterKit.headline}
                      subheadline={starterKit.subheadline}
                      cta={starterKit.cta}
                      iconSrc={metricsIcon}
                      widgetHeadline1={starterKit.widgetHeadline1}
                      widgetHeadline2={starterKit.widgetHeadline2}
                      widgetHeadline3={starterKit.widgetHeadline3}
                      widgetSubHeadline1={starterKit.widgetSubHeadline1}
                      widgetSubHeadline2={starterKit.widgetSubHeadline2}
                      widgetSubHeadline3={starterKit.widgetSubHeadline3}
                    />
                  )}
                {isDistrictAdmin && (
                  <DistributionSettingsNotification districtId={hierarchyId} />
                )}
                {(isDistrictAdmin || isDistrictApprover) &&
                  !scheduleDistributionVisited && (
                    <ScheduleDistributionNotification />
                  )}
                {!isOrgUser && doesShowDefault && (
                  <MessageBanner
                    headline={welcome.headline}
                    subheadline={generateWelcomeSubHeadline()}
                    iconSrc={desktopBlueIcon}
                    isSubheadlineHTML
                  />
                )}

                {isOrgUser && doesShowDefault && (
                  <MessageBanner
                    headline={welcome_org.headline}
                    subheadline={welcome_org.subheadline}
                    iconSrc={desktopBlueIcon}
                  />
                )}

                {showNoRoleBanner && (
                  <MessageBanner
                    headline={noRole.headline}
                    subheadline={noRole.subheadline}
                    iconSrc={desktopBlueIcon}
                  />
                )}

                {isCommunityFreeUser && isOrgUploaderFeaturesEnabled && (
                  <MessageBanner
                    headline={communityFree.headline}
                    subheadline={communityFree.subheadline}
                  >
                    <ButtonSecondarySmall
                      data-testid="click-dashboard-community-free-deal"
                      onClick={() => {
                        window.location.href = APP_ROUTES.commFreeQuestionnaire;
                      }}
                    >
                      {communityFree.cta}
                    </ButtonSecondarySmall>
                  </MessageBanner>
                )}

                {isOrgUser && isOrgUploaderFeaturesEnabled && (
                  <CyberDealsBanner starterKit={!!hasStarterKit} />
                )}

                {isOrgOrCommunityFree &&
                  isOrgUploaderFeaturesEnabled &&
                  requiresLodBannerPrompt && (
                    <MessageBanner
                      headline={verifyStatus.headline}
                      subheadline={verifyStatus.subheadline}
                      variant="pop"
                      iconSrc={distributionIcon}
                    >
                      <ButtonSecondarySmall
                        data-testid="click-dashboard-verify-status"
                        onClick={() => {
                          if (isOrgUser) {
                            window.location = REACT_APP_PEACHJAR_ORG_SETTINGS_LINK;
                          } else {
                            window.location = `${REACT_APP_PEACHJAR_HOMEPAGE}/index.php?a=4&b=4`;
                          }
                        }}
                      >
                        {verifyStatus.cta}
                      </ButtonSecondarySmall>
                    </MessageBanner>
                  )}
                {isOrgUser
                  ? generateDashboardItems(permissions, dashboardFilter, data)
                  : generateTrainingGuideItems(permissions)}
              </GenerateSubHeader>
              <CyberSaleDisclaimer />
            </>
          )}
        </Query>
      )}
    </>
  );
};

// Override default material-ui styles
const materialOverrides = {
  elevation2: {
    boxShadow: 'none',
  },
};

// Classes
const cn = {
  viewDashboard: css`
    margin-bottom: 130px;
  `,
  icon: css`
    padding: 0 14px 0 0;
  `,
  sectionHeadline: css`
    padding-bottom: 0.6rem;
  `,
  headline: css`
    font-size: 17px;
    font-weight: 600;
  `,
  section: css`
    display: flex;
    justify-content: center;
    margin-top: 0 !important;
    text-align: center;
  `,
  sectionBG: css`
    padding: 30px;
    width: 490px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.03);
      border-radius: 6px;
    }
  `,
  sectionBGdisabled: css`
    &:hover {
      background-color: #f5f5f5;
    }
  `,
  sectionImg: css`
    cursor: pointer;
  `,
  sectionContent: css`
    margin-top: 32px;
    padding: 0 46px;
  `,
  sectionTitle: css`
    font-size: 19px;
    font-weight: 600;
    cursor: pointer;
  `,
  sectionDisabled: css`
    cursor: default !important;
  `,
  lastCard: css`
    margin-top: 46px;
  `,
  covidOrderedList: css`
    padding-left: 1rem;
  `,
  removedAccountBanner: css`
    padding-top: 25px;
  `,
  guideContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px;
  `,
};

Dashboard.fragments = {
  sodUser: gql`
    fragment Dashboard_sodUser on SodUser {
      firstName
      permission {
        adminLevel
        approverLevel
        uploaderLevel
      }
    }
  `,
};

const mapDispatchToProps = dispatch => ({
  handleWarning: key => {
    dispatch(notifyWarn(NOTIFICATIONS[key]));
  },
});

export default connect(
  state => ({ flags: state.flags }),
  mapDispatchToProps
)(withStyles(materialOverrides)(withRouter(Dashboard)));
