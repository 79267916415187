import config from '../config';

const { REACT_APP_PEACHJAR_ACCOUNT_LINK } = config;

export const BRANDBAR_HEIGHT = '56px';

export const SIDEBAR_WIDTH = {
  sm: '64px',
  md: '208px',
};

export const BREAKPOINTS = {
  xs: '600px',
  sm: '960px',
  md: '1280px',
  lg: '1920px',
};

// app level notifications
export const NOTIFICATIONS = {
  errorKey: 'PJ_APP_ERROR',
  roleDenied:
    'Your assigned role does not have access to this area. Contact your district or school administrator for more information.',
  logoutFailed: 'Log out failed. Please try again.',
  removeCampaignSuccess: 'Your draft flyer has been removed.',
  staffAccountSubscriptionSuccess: 'Account Information updated.',
  removeCampaignError: 'Failed to remove draft flyer. Please try again.',
  removeFlyerSuccess: 'Your flyer has been removed.',
  removeFlyerError: 'Failed to remove flyer. Please try again.',
  removeFlyerWarning:
    'Flyer can only be removed in Flyer Reporting by the person who uploaded it. Please contact your District Admin to remove the flyer in All Approvals.',
  flyerDetailsInvalid:
    "Sorry, the flyer details you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer details you are looking for.",
  flyerReportInvalid:
    "Sorry, the flyer report you are looking for was moved or doesn't exist. Try one of the flyers in Flyer Reporting to find the flyer report you are looking for.",
  clipboardSuccess: 'Link has been copied to clipboard.',
  clipboardError: 'Failed to copy link to clipboard. Please try again.',
  paymentSuccess:
    'Your purchase is complete. View your transaction history for your most recent purchases.',
  paymentError:
    'Your payment did not go through due to a system error. Please try to submit payment again. If you need assistance contact us at (858) 997-2117.',
  genericError:
    'Something went wrong. Please try again. If you need assistance contact us at (858) 997-2117.',
  accountInfoSaveError: 'Could not update your information. Please try again.',
  accountInfoSaveSuccess: 'Your information has been saved.',
  accountInfoFileUploadSuccess: 'Your file has been uploaded.',
  accountInfoFileUploadError: 'Could not upload your file. Please try again.',
  accountInfoFileRemoveSuccess: 'Your file has been removed.',
  accountInfoFileRemoveError: 'Could not remove your file. Please try again.',
  accountInfoResetPasswordEmailSuccess:
    'A link to reset your password has been emailed to you.',
  accountInfoResetPasswordEmailError:
    'Failed to email a link to reset your password. Please try again.',
  passwordResetSendSuccess:
    'A link to reset your password has been emailed to you.',
  passwordResetSendFailure:
    'Failed to email a link to reset your password. Please try again.',
  commFreeAccessError:
    'Once a Community Free flyer application has been submitted, it can not be edited.',
  switchAccountError: 'An error occurred trying to switch accounts.',
  accountInfoEmailAlreadyExists: 'An account with this email address already exists. Please check the spelling or try another email address.'
};

export const PARTICIPATION_FEES = {
  '$0.00': 'Free',
  '$1.00': '$1 \u2013 $50',
  '$51.00': '$50 \u2013 $100',
  '$101.00': '$100 \u2013 $200',
  '$201.00': '$200+',
};

export const APP_ROUTES = {
  account: '/account',
  account_info: '/account/information',
  account_transactions: '/transactions/history',
  dashboard: '/dashboard',
  campaigns: '/campaigns',
  commFreeQuestionnaire: `${config.REACT_APP_PORTAL_BFF_URL}/communityfree`,
  contact_us: '/contact-us',
  deals: '/deals',
  faq: '/faq',
  foo: '/foo',
  postFlyer: '/campaigns/create-campaign/new/prepare/upload',
  approvals: {
    index: '/approvals',
    pending: '/approvals/pending',
    history: {
      myApprovals: '/approvals/my-approvals',
      allApprovals: '/approvals/all-approvals',
    },
  },
  help: '/help',
  reporting: {
    index: '/reporting',
    allFlyers: '/reporting/all-flyers',
    myFlyers: '/reporting/my-flyers',
    report: '/reporting/:page/report/:flyerid',
  },
  staff: '/staff',
  schools: '/schools',
  transactions: '/account/transactions',
  parents: '/parents',
  organizations: '/organizations',
  district: '/district',
  credits: {
    index: '/credits',
    cancelled: '/credits/cancelled',
    completed: '/credits/completed',
    // Coming soon: "history", etc.
    history: '/credits/history',
  },
  orgSettings: '/settings/organization',
};

export const QUERY_MAPPING = {
  flyerDetails: 'flyer',
  flyerDetailsId: 'id',
};

export const DEALS_PROMO_CODES = Object.freeze({
  CYBERSALE20: '2020CYBER20',
  CYBERSALE50: '2020CYBER50',
});

export const DEALS_STORAGE_KEYS = Object.freeze({
  credits: 'pj_deals_credits',
  promoCodes: 'pj_deals_pr_codes',
});

export const PEACHJAR_ACCOUNT_LINK = REACT_APP_PEACHJAR_ACCOUNT_LINK;
